/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { MDBBtn, MDBInputGroup } from "mdb-react-ui-kit";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthLayout } from "../../../components";
import {
  // useLazyGetRestaurantProfileQuery,
  useRestaurantLoginMutation,
} from "../../../features/services/restaurant/service";
import { useAppDispatch } from "../../../app/hooks";
import {
  setUser,
  setUserInfo,
} from "../../../features/slice/restaurant/authSlice";
import Loader from "../../../components/loader";
import useWindowWidth from "../../../utils/methods/ui-hooks/getWindowWidth";
// import { registerServiceWorker } from "../../../registerServiceWorker";
import { subscribeUserToPush } from "../../../utils/pushNotification";

const data = {
  headingText: "Welcome back!",
  subText:
    "Sign in to stay connected with us and we’ll help you reach a wider audience and boost your sales. If you don’t have an account with us, sign up here.",
  btnText: "SIGN UP",
  btnPath: "/restaurant-portal/signup",
};

const initialData = {
  email: "",
  password: "",
};

export const RestaurantSignIn = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    password: "",
    email: "",
  });
  // destructuring properties of custom sign-in hook
  const [
    restaurantLogin,
    { data: loginData, isSuccess, isError, error: loginError },
  ] = useRestaurantLoginMutation();

  const handleShowPassword = () => setShowPassword(!showPassword);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const screenWidth = useWindowWidth();

  const validEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const handleEmail = (e: any) => {
    if (e.target.value.match(validEmailRegex)) {
      setErrors({ ...errors, email: "" });
      setFormData({ ...formData, email: e.target.value });
    } else {
      setErrors({ ...errors, email: "Invalid email" });
    }
  };

  const handlePassword = (e: any) => {
    if (e.target.value.length > 7) {
      setFormData({ ...formData, password: e.target.value });
      setErrors({ ...errors, password: "" });
    } else {
      setErrors({
        ...errors,
        password: "Password must be atleast 8 characters long",
      });
    }
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (
      formData.email &&
      formData.password &&
      !errors.email &&
      !errors.password
    ) {
      try {
        await restaurantLogin(formData);
      } catch (err) {}
    }
  };

  // const handleSubscription = async (token: string) => {
  //   try {
  //     const registration = await navigator.serviceWorker.ready;

  //     // const applicationServerKey =
  //     //   "BAfFwyP2-NBeLcQvIUDNw3ZbgwtRxyToLNrd0Oo3_n7L9a43WHXTV-O1hmuy75NM2tb4ABM2RdB4RqnVATW2hf4";
  //     const applicationServerKey =
  //       process.env.REACT_APPLICATION_SERVER_KEY ||
  //       "BAfFwyP2-NBeLcQvIUDNw3ZbgwtRxyToLNrd0Oo3_n7L9a43WHXTV-O1hmuy75NM2tb4ABM2RdB4RqnVATW2hf4";
  //     const subscription = await registration.pushManager.subscribe({
  //       userVisibleOnly: true,
  //       applicationServerKey: applicationServerKey,
  //     });

  //     const subscriptionData = {
  //       endpoint: subscription.endpoint,
  //       keys: {
  //         auth: subscription?.toJSON()?.keys?.auth,
  //         p256dh: subscription?.toJSON()?.keys?.p256dh,
  //       },
  //     };

  //     await sendSubscriptionToServer(subscriptionData, token);
  //   } catch (error) {
  //     console.error("Error subscribing to push notifications:", error);
  //   }
  // };

  // const sendSubscriptionToServer = async (subscription: any, token: string) => {
  //   try {
  //     const response = await fetch(
  //       "https://api.peakreachdelivery.com/api/v1/restaurants/subscribe/web-notification",
  //       {
  //         method: "POST",
  //         body: JSON.stringify(subscription),
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,PEAKREACH_TOKEN

  //         },
  //       }
  //     );

  //     console.log("Subscription resp:", response);
  //   } catch (error) {}
  // };

  const getUserInfo = async (token: string) => {
    try {
      const response = await fetch(
        "https://api.peakreachdelivery.com/api/v1/restaurants/profile",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const res = await response.json();
        dispatch(setUserInfo(res));
      }
    } catch (error) {
      console.error("couldn't get user info", error);
    }
  };

  useEffect(() => {
    if (isSuccess && !loginData?.token) {
      toast.success(loginData?.message);
      navigate("/restaurant-portal/account-activation");
    }

    if (isSuccess && loginData?.token) {
      setLoading(false);
      sessionStorage.setItem("PEAKREACH_REFRESH", loginData.refresh);
      dispatch(
        setUser({
          token: loginData?.token,
          message: loginData?.message,
        })
      );
      sessionStorage.setItem("_id", loginData?._id);
      toast.success(loginData?.message);
      navigate("/restaurant-portal/dashboard");
      // requestNotificationPermission();
      // registerServiceWorker();
      getUserInfo(loginData?.token);
      subscribeUserToPush();
      // handleSubscription(loginData?.token);
    }

    if (isError) {
      setLoading(false);
      if (loginError && "status" in loginError) {
        let { data }: any = loginError;
        toast.error(data?.error || "Login Failed!");
      }
    }
  }, [isSuccess, isError]);

  return (
    <AuthLayout
      headingText={data.headingText}
      subText={data.subText}
      btnText={data.btnText}
      btnPath={data.btnPath}
    >
      <div className="sign-in-container">
        <h1>Sign in to Peakreach</h1>
        {/* <p>vicolou@gmail.com vicolus234562</p> */}
        <form className="mt-5" onSubmit={handleLogin}>
          <MDBInputGroup size="lg">
            <input
              placeholder="Email"
              type="text"
              className="form-control"
              onChange={handleEmail}
              required
            />
          </MDBInputGroup>
          {errors.email && <small className="error-text">{errors.email}</small>}
          <div>
            <MDBInputGroup className="password-group mt-4" size="lg">
              <input
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                className="form-control"
                onChange={handlePassword}
                required
              />
              <div onClick={handleShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </div>
            </MDBInputGroup>
            {errors.password && (
              <small className="error-text">{errors.password}</small>
            )}
            <Link to="/restaurant-portal/forgotpassword" className="fgt-psd">
              Forgot Password?
            </Link>
          </div>

          <MDBBtn className="signin-btn mt-5 w-100" size="lg" type="submit">
            {loading ? (
              <Loader
                height="27px"
                width="27px"
                borderTopColor="#FFAE0A"
                borderTopWidth="5px"
                borderWidth="5px"
              />
            ) : (
              "SIGN IN"
            )}
          </MDBBtn>

          {screenWidth <= 799 && (
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              Don't have an account?
              <Link to="/restaurant-portal/signup" style={{ color: "#FFAE0A" }}>
                &nbsp;Sign up
              </Link>
            </p>
          )}
        </form>
      </div>
    </AuthLayout>
  );
};
