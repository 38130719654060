import ReactDOM from "react-dom";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

// const publicVapidKey =
//   "BEVJeagagqLu7AjfNxWC532akuy8zGzlURsfL8b38wEpXfFcY5Sf1fuQEUuWZYNshx8pcM3shcARZfSXbg9dIpo";

// if ("Notification" in window) {
//   Notification?.requestPermission()
//     .then((permission) => {
//       if (permission === "granted") {
//         if ("serviceWorker" in navigator) {
//           send().catch((err) => console.error(err));
//         }
//       }
//     })
//     .catch((error) => {});
// }

// async function send() {
//   try {
//     // Register Service Worker
//     const register = await navigator.serviceWorker.register(
//       "/serviceWorker.js",
//       {
//         scope: "/",
//       }
//     );

//     // Register Push
//     const subscription = await register.pushManager.subscribe({
//       userVisibleOnly: true,
//       applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
//     });

//     // Send Push Notification
//     const token = localStorage.getItem("PEAKREACH_TOKEN");
//     await fetch(
//       "https://api.peakreachdelivery.com/api/v1/restaurants/subscribe/web-notification",
//       {
//         method: "POST",
//         body: JSON.stringify(subscription),
//         headers: {
//           "content-type": "application/json",
//           authorization: `Bearer ${token}`,
//         },
//       }
//     );
//   } catch (err) {}
// }

// function urlBase64ToUint8Array(base64String: any) {
//   const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
//   const base64 = (base64String + padding)
//     // eslint-disable-next-line no-useless-escape
//     .replace(/\-/g, "+")
//     .replace(/_/g, "/");

//   const rawData = window.atob(base64);
//   const outputArray = new Uint8Array(rawData.length);

//   for (let i = 0; i < rawData.length; ++i) {
//     outputArray[i] = rawData.charCodeAt(i);
//   }
//   return outputArray;
// }

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
