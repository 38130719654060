export const subscribeUserToPush = async () => {
  if ("serviceWorker" in navigator && "PushManager" in window) {
    const permission = await Notification?.requestPermission();
    console.log("Permission status", permission);
    if (permission !== "granted") {
      return;
    }
    const registration = await navigator?.serviceWorker?.ready;

    const publicVapidKey =
      "BEVJeagagqLu7AjfNxWC532akuy8zGzlURsfL8b38wEpXfFcY5Sf1fuQEUuWZYNshx8pcM3shcARZfSXbg9dIpo";
    const subscription = await registration?.pushManager?.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicVapidKey), // Replace with your public key
    });

    // Send subscription to the backend
    await fetch(
      "https://api.peakreachdelivery.com/api/v1/restaurants/subscribe/web-notification",
      {
        // Replace with your subscription endpoint
        method: "POST",
        body: JSON.stringify(subscription),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("PEAKREACH_TOKEN")}`,
        },
      }
    );

  }
};

// Utility function to convert the VAPID key
const urlBase64ToUint8Array = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
